


















































import Vue from 'vue'
import CardTitle from '@/components/CardTitle.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { errorMessages } from '@/utils/helpers'
import { CreditInsuranceDTO, CreditInsuranceStatus } from '@/services/creditInsurance/types'
import CreditInsuranceService from '@/services/creditInsurance'
import EditButton from '@/components/buttons/EditButton.vue'
import DatePicker from '@/components/DatePicker.vue'
import StatusTextFormatter from '@/views/CreditInsurance/partials/StatusTextFormatter.vue'
import FileDropzone from '@/components/FileDropzone.vue'

export default Vue.extend({
  name: 'EditCreditInsurance',
  components: { StatusTextFormatter, DatePicker, EditButton, SubmitButton, CancelButton, CardTitle, FileDropzone },
  props: {
    id: { type: Number, default: undefined },
    clientId: { type: String, default: undefined },
    clientName: { type: String, default: undefined },
  },
  data: function (): {
    dialog: boolean
    creditInsurance: CreditInsuranceDTO
  } {
    return {
      dialog: false,
      creditInsurance: {
        totalAmount: 0,
        remainingAmount: 0,
        expiresAt: '',
        status: CreditInsuranceStatus.PENDING,
        notes: '',
      },
    }
  },
  methods: {
    setImage(value: { expiresAt: string; base64: string; extension: string }): void {
      this.creditInsurance.base64 = value.base64
      this.creditInsurance.extension = value.extension
    },
    async fetchItem(): Promise<void> {
      const { data } = await CreditInsuranceService(this.clientId).find(this.id)
      this.creditInsurance = data.data.creditInsurance
    },
    async update(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const response = await CreditInsuranceService(this.clientId).update(this.id, this.creditInsurance)
        if (response) {
          this.$emit('updated')
          this.$toast.success(response.data.message)
          this.dialog = false
        }
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
    close() {
      this.dialog = false
      this.creditInsurance.expiresAt = ''
      this.creditInsurance.remainingAmount = 0
      this.creditInsurance.totalAmount = 0
      this.creditInsurance.status = CreditInsuranceStatus.PENDING
      this.creditInsurance.notes = ''
    },
  },
})
