

























































































































import Vue from 'vue'
import type { ClientDTO } from '@/services/client/types'
import { email, ValidationRule } from 'vuelidate/lib/validators'
import CountriesSelect from '@/components/selects/CountriesSelect.vue'

export default Vue.extend({
  name: 'ClientDetails',
  components: { CountriesSelect },
  data: function (): {
    rules: { required: (value: string) => string | boolean; email: (value: string) => ValidationRule }
    loading: boolean
  } {
    return {
      rules: {
        required: (value: string) => !!value || this.$tc('validation.required'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        email: (value: string) => email(value) || this.$tc('validation.email'),
      },
      loading: false,
    }
  },
  props: {
    client: {
      type: Object as () => ClientDTO,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: 'client',
  },
  computed: {
    computedClient: {
      get(): ClientDTO {
        return this.client
      },
      set(value: ClientDTO): void {
        this.$emit('input', value)
      },
    },
  },
})
