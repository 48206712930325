


































































import Vue from 'vue'
import CardTitle from '@/components/CardTitle.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import ClientService from '@/services/client'
import DeactivateButton from '@/components/buttons/DeactivateButton.vue'
import type { ClientDeactivationDTO } from '@/services/client/types'
import { errorMessages } from '@/utils/helpers'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default Vue.extend({
  name: 'DeactivateClientForm',
  components: { SubmitButton, CancelButton, DeactivateButton, CardTitle },
  props: {
    id: { type: String, default: '' },
    openTab: { type: Number },
    thisTabIndex: { type: Number },
  },
  data: function (): { clientDeactivation: ClientDeactivationDTO; formInitiated: boolean } {
    return {
      formInitiated: false,
      clientDeactivation: {
        id: 0,
        reason: '',
        initiated: false,
        directorConfirmed: false,
        financeConfirmed: false,
        administratorConfirmed: false,
        initiator: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          roleId: -1,
        },
        director: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          roleId: -1,
        },
        finance: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          roleId: -1,
        },
        administrator: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          roleId: -1,
        },
        createdAt: '',
      },
    }
  },
  watch: {
    openTab: function (tab) {
      if (tab === this.thisTabIndex) this.fetchItem()
    },
  },
  methods: {
    initForm(): void {
      this.formInitiated = true
    },
    async deactivateClient(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const response = await ClientService.deactivate(this.id, this.clientDeactivation)
        if (response) {
          this.$emit('deactivate')
          this.$toast.success(response.data.message)
          this.clearModal()
        }
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
    clearModal(): void {
      this.clientDeactivation = {
        id: 0,
        reason: '',
        initiated: false,
        directorConfirmed: false,
        financeConfirmed: false,
        administratorConfirmed: false,
        initiator: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          roleId: -1,
        },
        director: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          roleId: -1,
        },
        finance: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          roleId: -1,
        },
        administrator: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          roleId: -1,
        },
        createdAt: '',
      }
      this.$emit('canceled')
    },
    async fetchItem(): Promise<void> {
      const { data } = await ClientService.deactivationStatus(this.id)
      if (data.data) {
        this.clientDeactivation = data.data
        await this.$forceUpdate()
        if (this.clientDeactivation.initiated) this.initForm()
      }
    },
    async confirmDeactivation(): Promise<void> {
      await ClientService.confirmDeactivation(this.id, this.clientDeactivation.id)
      await this.fetchItem()
    },
  },
  mounted() {
    this.fetchItem()
  },
})
