import api from '@/plugins/api'
import type { GetAllInvoicesResponse } from './types'
import {
  CreateInvoicePaymentsResponse,
  FindInvoicePaymentsResponse,
  InvoicePaymentsDTO,
  StatisticsInvoicesResponse,
} from './types'

const InvoiceService = {
  all(query = ''): Promise<GetAllInvoicesResponse> {
    return api().get(`/clients/invoices${query}`)
  },

  find(
    clientId: string | number,
    invoiceId: string | number,
    paymentId: string | number,
  ): Promise<FindInvoicePaymentsResponse> {
    return api().get(`/clients/${clientId}/invoices/${invoiceId}/payments/${paymentId}`)
  },

  create(payload: InvoicePaymentsDTO, clientId: string, invoiceId: number): Promise<CreateInvoicePaymentsResponse> {
    return api().post(`/clients/${clientId}/invoices/${invoiceId}/payments`, payload)
  },

  statistics(clientId: string): Promise<StatisticsInvoicesResponse> {
    return api().get(`/clients/${clientId}/invoices/statistics`)
  },
}

export default InvoiceService
