




















import type { DocumentTypeDTO } from '@/services/documentType/types'
import DocumentUploader from '@/views/Clients/tabs/DocumentUploader.vue'
import { defineComponent } from '@vue/composition-api'
import { ClientDocumentDTO } from '@/services/client/types'

export default defineComponent({
  name: 'Documents',
  components: { DocumentUploader },
  props: {
    documents: {
      type: Array as () => ClientDocumentDTO[],
      default: () => [],
    },
    documentTypes: {
      type: Array as () => DocumentTypeDTO[],
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clientId: {
      type: Number,
      required: true,
    },
  },
  model: {
    prop: 'documents',
  },
  methods: {
    getDocumentIndex(docType: DocumentTypeDTO, key: number): number {
      for (let index in this.documents) {
        if (this.documents[index].type === docType.name) {
          return parseInt(index)
        }
      }
      return key
    },
  },
})
