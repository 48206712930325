



































































import Vue from 'vue'
import CardTitle from '@/components/CardTitle.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import { CreditInsuranceDTO, CreditInsuranceStatus } from '@/services/creditInsurance/types'
import CreditInsuranceService from '@/services/creditInsurance'
import ShowButton from '@/components/buttons/ShowButton.vue'
import StatusTextFormatter from '@/views/CreditInsurance/partials/StatusTextFormatter.vue'
import { errorMessages } from '@/utils/helpers'
import FileDropzone from '@/components/FileDropzone.vue'

export default Vue.extend({
  name: 'ShowCreditInsurance',
  components: { StatusTextFormatter, ShowButton, CancelButton, CardTitle, FileDropzone },
  props: {
    id: { type: Number, default: undefined },
    clientId: { type: String, default: undefined },
  },
  data: function (): {
    dialog: boolean
    creditInsurance: CreditInsuranceDTO
    documentUrl: string | null
    documentPath: string | null
    pending: CreditInsuranceStatus.PENDING
    approved: CreditInsuranceStatus.APPROVED
    denied: CreditInsuranceStatus.DENIED
  } {
    return {
      dialog: false,
      creditInsurance: {
        expiresAt: '',
        remainingAmount: 0,
        totalAmount: 0,
        notes: '',
        status: CreditInsuranceStatus.EXPIRED,
      },
      documentUrl: null,
      documentPath: null,
      pending: CreditInsuranceStatus.PENDING,
      approved: CreditInsuranceStatus.APPROVED,
      denied: CreditInsuranceStatus.DENIED,
    }
  },
  methods: {
    async fetchItem(): Promise<void> {
      const { data } = await CreditInsuranceService(this.clientId).find(this.id)
      this.creditInsurance = data.data.creditInsurance
      this.documentUrl = data.data.creditInsurance.url ? data.data.creditInsurance.url : null
      this.documentPath = data.data.creditInsurance.path ? data.data.creditInsurance.path : null
    },
    async changeStatus(status: CreditInsuranceStatus): Promise<void> {
      this.creditInsurance.status = status
      const loader = this.$loading.show()
      try {
        const response = await CreditInsuranceService(this.clientId).update(this.id, this.creditInsurance)
        if (response) {
          this.$emit('updated')
          this.$toast.success(response.data.message)
          this.dialog = false
        }
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
  },
})
