























































































import { ClientDocumentDTO } from '@/services/client/types'
import { ref } from '@vue/composition-api'
import { defineComponent } from '@vue/composition-api'
import { errorMessages } from '@/utils/helpers'
import ClientService from '@/services/client'

export default defineComponent({
  name: 'FileDropzone',
  setup() {
    const active = ref(false)
    const toggleActive = () => {
      active.value = !active.value
    }
    return { active, toggleActive }
  },
  props: {
    documentType: {
      type: Object,
      default: () => {
        return { id: '', name: '' }
      },
    },
    document: {
      type: Object as () => ClientDocumentDTO,
      required: true,
      default: () => {
        return {
          base64: '',
          documentTypeId: '',
          expiresAt: '',
          extension: '',
        }
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    documentKey: {
      type: Number,
      default: 1,
    },
    clientId: {
      type: Number,
      required: false,
    },
  },
  model: {
    prop: 'document',
  },
  data() {
    return {
      image: '',
      menu: false,
      fileName: '',
    }
  },
  computed: {
    computedDocument: {
      get(): any { // eslint-disable-line
        return this.document
      },
      set(value: ClientDocumentDTO): void {
        this.$emit('input', value)
      },
    },
    imageSource: {
      get(): string {
        return !this.computedDocument.url
          ? 'data:image/png;base64,' + this.computedDocument.base64
          : this.computedDocument.url
      },
      set(value: string): void {
        this.$emit('input', value)
      },
    },
    isImage: {
      get(): boolean {
        const allowedImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'tif', 'svg']
        if (this.computedDocument.base64) {
          return allowedImageExtensions.includes(this.computedDocument.extension)
        } else if (this.computedDocument.url) {
          const extension = this.computedDocument.path.split('.').pop()
          return allowedImageExtensions.includes(extension)
        }
        return false
      },
      set(value: string): void {
        this.$emit('input', value)
      },
    },
    isPdf: {
      get(): boolean {
        if (this.computedDocument.base64) {
          return this.computedDocument.extension === 'pdf'
        } else if (this.computedDocument.url) {
          const extension = this.computedDocument.path.split('.').pop()
          return extension === 'pdf'
        }
        return false
      },
      set(value: string): void {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    getExtension(file: File): string {
      return file.name.split('.').pop() || ''
    },
    getBase64(base64: string): string {
      return base64.split(',').pop() || ''
    },
    uploadFile(e: any) { // eslint-disable-line
      if (!e.target.files) {
        return
      }

      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = () => {
        this.computedDocument = {
          base64: this.getBase64(reader.result as string),
          documentTypeId: this.documentType.id,
          expiresAt: this.computedDocument.expiresAt,
          extension: this.getExtension(e.target.files[0]),
        }
        this.fileName = e.target.files[0].name
        this.image = URL.createObjectURL(e.target.files[0])
      }
    },
    dragFile(e: DragEvent) {
      if (!e.dataTransfer?.files) {
        return
      }
      const [file] = e.dataTransfer?.files

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async () => {
        this.computedDocument = {
          base64: this.getBase64(reader.result as string),
          documentTypeId: this.documentType.id,
          expiresAt: this.computedDocument.expiresAt,
          extension: this.getExtension(file),
        }
        this.fileName = file.name
        this.image = URL.createObjectURL(file)
      }
    },
    async deleteFile() {
      try {
        if (!this.computedDocument.base64 && this.clientId) {
          await ClientService.deleteDocument(this.clientId, this.computedDocument.id)
        }
      } catch (err: any) { // eslint-disable-line
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
        } else {
          this.$toast.error(this.$t('somethingWentWrong') as string)
        }
        return
      }

      this.computedDocument.url = ''
      this.computedDocument.path = ''
      this.computedDocument.base64 = ''
      this.computedDocument.extension = ''
      this.computedDocument.documentTypeId = ''
      this.computedDocument.expiresAt = ''

      this.fileName = ''
      this.image = ''
    },
    downloadFile() {
      const linkSource = `data:application/${this.computedDocument.extension};base64,${this.computedDocument.base64}`
      const downloadLink = document.createElement('a')

      downloadLink.href = this.computedDocument.base64 ? linkSource : this.computedDocument.url
      downloadLink.target = '_blank'
      downloadLink.download = this.fileName !== '' ? this.fileName : 'document'
      downloadLink.click()
    },
  },
})
