import api from '@/plugins/api'
import type {
  CreateCreditInsuranceResponse,
  DeleteCreditInsuranceResponse,
  FindCreditInsuranceResponse,
  GetAllCreditInsuranceResponse,
  UpdateCreditInsuranceResponse,
} from './types'

const CreditInsuranceService = (
  clientId?: string,
): {
  all(query: string): Promise<GetAllCreditInsuranceResponse>
  find(id: string | number): Promise<FindCreditInsuranceResponse>
  create(payload: Record<string, unknown>): Promise<CreateCreditInsuranceResponse>
  update(id: string | number, payload: Record<string, unknown>): Promise<UpdateCreditInsuranceResponse>
  delete(id: string | number): Promise<DeleteCreditInsuranceResponse>
} => ({
  all(query = '') {
    return api().get(`/clients/${clientId}/credit-insurances${query}`)
  },

  find(id: string | number) {
    return api().get(`/clients/${clientId}/credit-insurances/${id}`)
  },

  create(payload: Record<string, unknown>) {
    return api().post(`/clients/${clientId}/credit-insurances`, payload)
  },

  update(id: string | number, payload: Record<string, unknown>) {
    return api().put(`/clients/${clientId}/credit-insurances/${id}`, payload)
  },

  delete(id: string | number) {
    return api().delete(`/clients/${clientId}/credit-insurances/${id}`)
  },
})

export default CreditInsuranceService
