



















































import { ClientUserDTO } from '@/services/client/types'
import { defineComponent } from '@vue/composition-api'
import { email } from 'vuelidate/lib/validators'

export default defineComponent({
  name: 'UserDetails',
  data() {
    return {
      rules: {
        required: (value: string) => !!value || this.$tc('validation.required'),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        email: (value: string) => email(value) || this.$tc('validation.email'),
      },
    }
  },
  props: {
    user: {
      type: Object as () => ClientUserDTO,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: 'user',
  },
  computed: {
    computedUser: {
      get(): ClientUserDTO {
        return this.user
      },
      set(value: string): void {
        this.$emit('input', value)
      },
    },
  },
})
