



























































import Vue from 'vue'
import CardTitle from '@/components/CardTitle.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import PaymentForm from '@/views/Invoice/PaymentForm.vue'
import { ResponsePaymentDto } from '@/services/invoice/types'

export default Vue.extend({
  name: 'InvoicePayments',
  components: {
    CancelButton,
    CardTitle,
    PaymentForm,
  },
  props: {
    id: {
      type: Number,
      default: -1,
    },
    unpaid: {
      type: Number,
      required: true,
    },
    clientId: {
      type: String,
      default: '',
    },
    paymentStatus: {
      type: String,
      default: '',
    },
    invoiceRemaining: {
      type: Number,
      required: true,
    },
    invoicePayments: {
      type: Array as () => ResponsePaymentDto[],
      required: true,
    },
  },
  data: function (): {
    dialog: boolean
  } {
    return {
      dialog: false,
    }
  },
  methods: {
    closeModal() {
      this.dialog = false
    },
  },
})
